<template>
  <section>
    <div class="grid md:grid-9-3">
      <div class=" h-65vh position-relative" :style="{backgroundImage:'url('+background+')'}">

        <transition name="fade" v-for="(panel,i) in panels">
          <div
              v-if="panel.id===$store.state.electricity.id"
              style="z-index:1; right:0; bottom: 20%; overflow-x: hidden"
              class="w-75p position-absolute">
              <img :src="$store.state.electricity.productImage" alt="boat" class="w-100p d-block">
              <div class="position-relative">
                  <img :src="bottom" alt="boat" class="w-100p d-block">
                  <div class="position-absolute w-75p boxes-position h-100p" style="left: 20%; z-index:3">
                      <div class="d-flex h-100p w-100p" style="z-index: 2; ">
                          <!--            Batteries-->
                          <div class="b-1 b-transparent hover:b-gray hover:bg-gray third" @mouseenter="explanation.battery=true"
                               @mouseleave="explanation.battery=false">

                              <div class="d-flex h-90p">
                                  <div class="third box m-1 bg-warning grid">
                                      <i class="las la-bolt font-lg place-x-center place-y-center text-white"></i>
                                  </div>
                                  <div class="third  box m-1"
                                       :class="{'bg-white-50':!batteryBoxes[0], 'bg-warning':batteryBoxes[0]}"></div>
                                  <div class="third  box m-1"
                                       :class="{'bg-white-50':!batteryBoxes[1], 'bg-warning':batteryBoxes[1]}"></div>
                              </div>
                              <p class="text-white m-2 text-shadow position-absolute position-top-0" v-show="explanation.battery">Battery capacity</p>

                          </div>

                          <!--            Water Tanks-->
                          <div class="b-1 b-transparent hover:b-gray hover:bg-gray third" @mouseenter="explanation.tanks=true"
                               @mouseleave="explanation.tanks=false">
                              <div class="d-flex h-90p">
                                  <div class="third  box m-1 bg-primary grid">
                                      <i class="las la-tint font-lg place-x-center place-y-center text-white"></i>
                                  </div>
                                  <div class="third  box m-1"
                                  :class="{'bg-white-50':!waterTankBoxes[0], 'bg-primary':waterTankBoxes[0]}"></div>
                                  <div class="third  box m-1"
                                  :class="{'bg-white-50':!waterTankBoxes[1], 'bg-primary':waterTankBoxes[1]}"></div>
                              </div>
                              <p class="text-white m-2 text-shadow position-absolute position-top-0" v-show="explanation.tanks">Water capacity</p>

                          </div>
                          <!--            Treatment-->
                          <div class="b-1 b-transparent hover:b-gray hover:bg-gray third" @mouseenter="explanation.treatment=true"
                               @mouseleave="explanation.treatment=false">
                              <div class="d-flex h-90p">
                                  <div class="third box m-1 grid"
                                  :class="{'bg-white-50':!$store.state.treatment.id, 'bg-yellow-light':$store.state.treatment.id}">
                                      <i class="las la-shower font-lg place-x-center place-y-center text-white"></i>
                                  </div>
                                  <div class="third  box m-1 grid"
                                  :class="{'bg-white-50':!$store.state.waste.id, 'bg-yellow':$store.state.waste.id}">
                                      <i class="las la-recycle font-lg place-x-center place-y-center text-white"></i>
                                  </div>
                              </div>
                              <p class="text-white m-2 text-shadow position-absolute position-top-0" v-show="explanation.treatment">Treatment</p>
                          </div>
                      </div>
                  </div>
              </div>

          </div>
        </transition>


      </div>
      <div class="m-l-2 p-3 d-flex f-column md:h-65vh">
        <selection-header :back-to="'/driving'" :steps="6" :current-step="6"/>
        <total-runner :step="6" class="f-1"/>
        <bottom-nav to="/add-ons" back-to="/driving"/>
      </div>
    </div>
    <div class="grid md:grid-6-6" v-if="waterItems&&electricityItems">
      <div class="m-x-1 f-1">
        <tab-shop :item="getElectricityItem" :show-inclusive="true" v-model:selected-tab="electricityTab" :multi-items="electricityItems" @update="electricitySelection">
          <i class="las la-bolt text-warning"></i> Electricity
        </tab-shop>
      </div>
      <div class="m-x-1 f-1">
        <tab-shop :item="getWaterItem" :show-inclusive="true" v-model:selected-tab="waterTab" :multi-items="waterItems" @update="waterSelection">
          <i class="las la-tint text-primary"></i> Water
        </tab-shop>
      </div>


    </div>

  </section>

</template>

<script>
import api from "@/api";
import selectionHeader from "@/components/selectionHeader";
import solarLower from "@/assets/solar-lower.jpg";
import totalRunner from "@/components/totalRunner"
import TabShop from "@/components/TabShop"
import {orgamaxImage} from "@/composables/orgamax";
import BottomNav from "@/components/BottomNav";
import WaterHorizon from "@/assets/water_horizon.jpg"
import LowerBoat from "@/assets/boat_lower.png"

export default {
  name: "Supply",
  components: {selectionHeader, TabShop, totalRunner, BottomNav},
  data: () => ({
    panels: [],
    batteries: [],
    water: [],
    waste: [],
    treatment: [],
    activePanels: 0,
    activeBattery: 0,
    activeWater: 0,
    activeWaste: 0,
    activeTreatment: 0,
    activeCategory: 'panels',
    bottom: LowerBoat,
    background: WaterHorizon,
    electricityItems: null,
    waterItems: null,
    electricityTab: 'solar',
    waterTab: 'waterTanks',
    explanation: {
      battery: false,
      tanks: false,
      treatment:false
    }
  }),
  async mounted() {
    try {

      const data = this.$store.getters.supply
        console.log({data})


      // electricity
      this.panels = data.filter(x => x.title.toLowerCase().includes('solar')).sort((a, b) => a.price - b.price)
      this.batteries = data.filter(x => x.title.toLowerCase().includes('battery')).sort((a, b) => a.price - b.price)

      // water tanks
      this.water = data.filter(x => x.title.toLowerCase().includes('water tanks')).sort((a, b) => a.price - b.price);

      // water treatment
      this.treatment = data.filter(x => {
        const lowercase = x.title.toLowerCase();
        return lowercase.includes('water treatment') && !lowercase.includes('waste')
      }).sort((a, b) => a.price - b.price);

      // water waste
      this.waste = data.filter(x => x.title.toLowerCase().includes('waste')).sort((a, b) => a.price - b.price);


      this.electricityItems = {
        solar: this.panels,
        battery: this.batteries,
      }
      this.waterItems = {
        'waterTanks': this.water,
        'waterTreatment': this.treatment,
        'wasteWater': this.waste
      }
      // set solar
      this.$store.commit('setElectricity', typeof this.$store.state.electricity.id !== 'undefined' ? this.$store.state.electricity : this.panels[0])

      // set battery
      this.$store.commit('setBattery', typeof this.$store.state.battery.id !== 'undefined' ? this.$store.state.battery : this.batteries[0])

      // set tanks
      this.$store.commit('setWater', typeof this.$store.state.water.id !== 'undefined' ? this.$store.state.water : this.water[0])

    } catch (e) {
      console.log(e)
    }

  },
  computed: {
    batteryBoxes() {
      if (this.$store.state.battery.title) {
        const capacity = Number(this.$store.state.battery.title.match(/\d+/))
        return [capacity > 15, capacity > 30]
      }
      return [false, false]
    },
    waterTankBoxes() {
      if (this.$store.state.water.description) {
        const capacity = Number(this.$store.state.water.description.match(/\d+/))
        return [capacity > 400, capacity > 660]
      }
      return [false, false]
    },
    getElectricityItem(){
      return this.$store.state[this.electricityTab === 'solar' ? 'electricity' : 'battery']
    },
    getWaterItem(){
      let setter;
      switch (this.waterTab) {
        case 'waterTanks':
          setter = 'water';
          break;
        case 'waterTreatment':
          setter = 'treatment';
          break;
        case 'wasteWater':
          setter = 'waste';
          break;
      }
      return this.$store.state[setter]
    }
  },
  methods: {
    orgamaxImage,
    electricitySelection(selection) {
      const setter = 'set' + (this.electricityTab === 'solar' ? 'Electricity' : 'Battery');
      this.$store.commit(setter, selection.item)
    },
    waterSelection(selection) {
      let setter = 'set';
      switch (this.waterTab) {
        case 'waterTanks':
          setter += 'Water';
          break;
        case 'waterTreatment':
          setter = this.$store.state.treatment.id === selection.item.id ? 'removeTreatment' : 'setTreatment';
          break;
        case 'wasteWater':
          setter = this.$store.state.waste.id === selection.item.id ? 'removeWaste' : 'setWaste';
          break;
      }
      this.$store.commit(setter, selection.item)
    }
  }
}
</script>

<style scoped>
.third{
    width: 33%;
}
.boxes-position{
    bottom: 0;
}
.text-shadow{
    text-shadow: 2px 1px 1px #5d6c7b;
}
.dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.box-offsets{
  height: 25%;
}
.box{
  height: 90%;
}
.boxes{
  right: 3%;
}


</style>